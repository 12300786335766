import React from 'react';
import {Link} from 'react-router-dom';
 
import contact from '../../data/Contact/contact'

const Header = () => {
    const phoneStr = "tel:" + contact[0].Phone.replace(/ /g, "")
    const mailStr = "mailto:" + contact[0].Email

	return (
 
    <div className="header_top_area">
        <div className="container">
            <div className="row">
                {/* Address / Phone / Email*/}
                <div className="col">
                    <div className="header_wrapper_1">
                        <ul>
                            <li> <a style={{color: "white"}} href={phoneStr}><i className="icon_phone"></i> {contact[0].Phone } </a> </li>
                            <li> <a style={{color: "white"}} href={mailStr}><i className="icon_mail_alt"></i>{contact[0].Email}</a></li>
                        </ul>
                    </div>
                </div>

                {/* SOCIAL ICON */}
                <div className="col">
                    <div className="header_social"> 
                        <a href={contact[0].Facebook}>
                            <i className="fa fa-facebook"></i>
                        </a> 
                        <a href={contact[0].Instagram}>
                            <i className="fa fa-instagram"></i>
                        </a> 
                        <a href={contact[0].Youtube}>
                            <i className="fa fa-youtube"></i>
                        </a>
                        <a href={contact[0].Linkedin}>
                            <i className="fa fa-linkedin"></i>
                        </a> 
                    </div>
                </div>

                {/* Quote button */}
                {/* <div className="col">
                    <div id="top" className="header_button text-right">
                        <a target="_blank" href="https://calendly.com/muddasirlaw/consult">Talk to a lawyer - FREE</a>
                    </div>
                </div> */}
                 {/* End: Quote button */}
                 
            </div>
        </div>
    </div>
 
	)
}


export default Header;