import React, { Component, Fragment } from 'react';  
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";  
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import {Link} from "react-router-dom"; 
 
import ServicesData from '../../data/Services/service-one' 
import BusinessServices from "./BusinessServices";
import TechnologyServices from "./TechnologyLaw";
import IncorporationServices from './Business/IncorporationServices';

import SectionTitle from "../../components/SectionTitles/SectionTitle";

class ServiceContentWrap extends React.Component {
    
    render() {
        var {service, subservice=false} = this.props;
        var isBusinessServices = false;
        var isTechnologyServices = false;
        var isIncorporationServices = false;
        
        if(subservice==true){
            if(service.id === 1){
                isIncorporationServices = true;
            }
        }
        else{
            if(service.id === 1){
                isBusinessServices = true;
            }
            if (service.id === 5){
                isTechnologyServices = true;
            }
        }

        

        return (
    
            <Fragment>
                <div className="col-md-12 col-sm-12 single_service_page_content"> 
                    {/* <img src={require('../../assets/images/' + service.singleThumb).default} alt="service" /> */}
                    <div className="single_service_title">
                        { !isIncorporationServices && (<h3>{service.title}</h3>)}
                        {<div dangerouslySetInnerHTML={{__html: service.description}}/>}                        
                        { isBusinessServices && (
                            <BusinessServices />
                        ) }
                        
                        { isTechnologyServices && (
                            <TechnologyServices />
                        ) }

                        { isIncorporationServices && (
                            <IncorporationServices />
                        ) }
                    </div>
                </div>

                {/* <Sidebar classes={'col-md-3 col-sm-12 single_service_side_bar'}>
                    <SidebarItem classes="single_service_category">
                        <List>
                            {
                                ServicesData.slice(0,7).map((serviceItem,id)=>(
                                    <LI key={id}><i className="fa fa-angle-right"></i>
                                        <Link to={`${process.env.PUBLIC_URL + `/service/${serviceItem.title.split(' ').join('-').toLowerCase()}?id=${serviceItem.id}`}`}>
                                            {serviceItem.title}
                                        </Link>
                                    </LI>
                                ))
                            }
                        </List> 
                    </SidebarItem>
                </Sidebar> */}

            </Fragment>
        );
    };

};

export default ServiceContentWrap;