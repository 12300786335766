import React, { Fragment } from "react";  
import { ListGroup, ListGroupItem, Nav, Row, Col, Card } from "react-bootstrap";
import { Image } from "react";
import Tab from 'react-bootstrap/Tab';

import startupImg from '../../assets/images/services/startup2.jpg';
import agreementImg from '../../assets/images/services/agreements.jpg';
import maintainingImg from '../../assets/images/services/maintaining.jpg';
import sellingImg from '../../assets/images/services/selling.jpg';

import SectionTitle from "../../components/SectionTitles/SectionTitle";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";

import businessImg from "../../assets/images/services/team.jpg";
import adviceImg from "../../assets/images/services/advice.jpg";
import techImg from "../../assets/images/services/cloud.jpg";

import successionImg from "../../assets/images/hands.jpg";


const BusinessServices = () => {
    return (
        <Fragment>
            <div className="service-section">
                <SectionTitle title="Setup, Launch & Grow" />
                <div className="row">
                    <div className="service-desc col-lg-8">
                        <p>Your small business startup is your pride and joy. We help with the following legal services:</p>
                        <ul>
                            <li>Incorporate a new ontario or federal Company</li>
                            <li>Register a federal or extra-provincial company</li>
                            <li>Draft Shareholder and Partnership and other Agreements</li>
                            <li>Update an incorporation that you did on your own</li>
                            <li>Compile/maintain a corporate minute book</li>
                            <li>Provision of registered and record office for the client companies</li>
                            <li>Virtual access to clients of their business records</li>
                        </ul>
                    </div>
                    <div className="service-image col-lg-4">
                        <img src={startupImg} alt="" style={{ alignSelf: 'center' }}/>
                    </div>
                </div>
            </div>

            <div className="service-section">
                <SectionTitle title="Technology Law" />
                <div className="row">
                    <div className="service-image col-lg-4">
                        <img src={techImg} alt="" style={{ alignSelf: 'center' }}/>
                    </div>
                    <div className="service-desc col-lg-8">
                    <p>We advise on various aspects of Technology business. If you are a startup we can be your best resource for legal needs in a cost-effective manner. </p>
                        <ul>
                            <li>Privacy Law</li>
                            <li>Software & Web Development Contract Management</li>
                            <li>Intellectual Property Rights Issues</li>
                            <li>Employment Matters</li>
                            <li>Legal Risk Assessments</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="service-section">
                <SectionTitle title="Business Structuring" />
                <div className="row">
                    <div className="service-desc col-lg-8">
                    <p>Business structuring is the process of helping our clients in choosing the best type of business structure that is best for their needs. We collaborate with and support business owners throughout the stages of growth. </p>
                        <ul>
                            <li>Incorporation / Registration</li>
                            <li>Business planning</li>
                            <li>Tax driven reorganizations</li>
                            <li>Corporate restructuring</li>
                            <li>Business succession planning</li>
                        </ul>
                    </div>
                    <div className="service-image col-lg-4">
                        <img src={businessImg} alt="" style={{ alignSelf: 'center' }}/>
                    </div>
                </div>
            </div>

            <div className="service-section">
                <SectionTitle title="Business Advice" />
                <div className="row">
                    <div className="service-image col-lg-4">
                        <img src={adviceImg} alt="" style={{ alignSelf: 'center' }}/>
                    </div>
                    <div className="service-desc col-lg-8">
                        <p>Our business advisory services includes advising board of directors and/or managment, issues related to best practices of corporate governance, drafting aggreements, negotiating and reviewing of various agreements such as shareholder agreements, partnership agreements, general contractual agreements, purchase and sale contracts, along with drafting of corporate by laws and articles.</p>

                            <ul>
                                <li>Advising the board of directors and/or management</li>
                                <li>Best practices of corporate governance</li>
                                <li>Drafting agreements</li>
                                <li>Drafting of corporate bylaws and articles.</li>
                            </ul>
                    </div>
                </div>
            </div>

            <div className="service-section">
                <SectionTitle title="Mergers and Acquisitions" />
                <div className="row">
                    <div className="service-desc col-lg-8">
                                             
                        <p>We advise businesses on sales and purchase of other business assets or shares by conducting due diligence, negotiating and drafting the purchase and sale agreement, and considering confidentiality issues in accordance with the transaction. Our aim in planning the transaction is to achieve the post-transaction client goals.</p>

                        <ul>
                            <li>Tax and ownership considerations</li>
                            <li>Share purchases</li>
                            <li>Asset Purchase</li>
                            <li>Due diligence</li>
                        </ul>

                    </div>
                    <div className="service-image col-lg-4">
                        <img src={sellingImg} alt="" style={{ alignSelf: 'center' }}/>
                    </div>
                </div>
            </div>

            <SectionTitle
                    smallTitle="What We Do"
                    title="Business Services" 
                />

            <div class="row row-cols-1 row-cols-md-3 g-4 gy-4">
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <a href="/business-law/incorporation-federal-ontario">
                                    <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                    Business Registration / Incorporations
                                </a>
                            </h5>    
                            <p class="card-text">Choosing the right form of a business is one of the most important decisions before initiating a business venture. We provide a free initial consultation and consider various factors to advise a specific form of business.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                            <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                            Corporate Governance / Business Maintenance Regulatory Compliance</h5>
                            <p class="card-text">Best practices of corporate governance vary at different stages of life cycle of a business, ranging from maintenance of corporate minute book to adoption of ESG principles.</p>
                        </div>

                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Legal Advice (Director & Management Liability)</h5>
                            <p class="card-text">We can advise the boards of directors and management regarding potential liability risks that can arise with a specific decision. The directors and the management have a fiduciary duty to the corporation.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Shareholder Agreements</h5>
                            <p class="card-text">A shareholder agreement is one of the most technical agreements to draft and negotiate. We will provide you with several options considering the size and the objective of the business and the shareholders.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Partnership Agreements</h5>
                            <p class="card-text">If you are looking to register a partnership or draft a partnership agreement give us a call and we will help you with it. In Ontario partnerships can of three forms, selecting a specific form depends on the needs of the business and the partners.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title"><span className="serv_icon_list"><i className="icon_documents_alt"></i></span>Letter of Intent</h5>
                            <p class="card-text">It is an important part of a M&A or joint venture transaction. We can help you draft the content of a letter of intent with care and professionalism.</p>
                        </div>

                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title"><span className="serv_icon_list"><i className="icon_documents_alt"></i></span>Employment</h5>
                            <p class="card-text">If you are looking to hire an employee you must take care of the applicable employment standard to your business structure. A properly drafted employment contract can help avoid employment related claims.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title"><span className="serv_icon_list"><i className="icon_documents_alt"></i></span>Contract Drafting & Negotiations</h5>
                            <p class="card-text">When contracting with the other side it is extremely important that you enter into a legally binding contract and you understand the agreement you enter. We can help you in all stages of contract formation whether it is drafting, reviewing, negotiation or execution.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title"><span className="serv_icon_list"><i className="icon_documents_alt"></i></span>Selling a business</h5>
                            <p class="card-text">We can help you buy a business by conducting due diligence of the business entity and the directors and taking care of all the legal requirements of the transaction.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Business Legal Risk Assessment & Advice</h5>
                            <p class="card-text">If you are starting up a business venture, there can several legal ways through which you can initiate and grow. We provide a free initial meeting with the lawyer who will analyze several factors before suggesting you a specific business structure. </p>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default BusinessServices;