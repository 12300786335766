import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader';

const BecomingAClient = () => {
    
    return(
        <Fragment>
            <MetaTags>
              <title> Becoming a client | Muddasir Law</title>
              <meta
                name="description"
                content="Becoming a client - Muddasir Law"
              />
            </MetaTags>

            <LayoutOne>
              <PageHeader
                bgImg={require('../assets/images/page_bg.jpg').default}
                title="Becoming a client" 
                services= {false}
              />
              
            <div className='container'>
                <p>Potential clients frequently have questions about how to engage lawyers for business, and how the relationship is established.</p>
                <p>Here's a quick explanation of the process I follow after you've scheduled your first appointment:
                    <ol>
                        <li>
                            <h4>Initial Meeting</h4>
                            <p>The initial meeting is free of charge. We will talk about discuss what your issue is and decide how and if we can help you. We also need to ensure that there is no conflict of interest with other individuals or entities.</p>
                        </li>
                        <li>
                            <h4>Client Information</h4>
                            <p>If after the initial meeting we decide to go ahead with the process; you will need to provide the following information:
                                <ul>
                                    <li>A form giving information regarding the service you hire us for</li>
                                    <li>A client identification form with your personal information.</li>
                                    <li>You need to submit a photo id.</li>
                                    <li>A link will provided to you with which you need to do the payment for the servie.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <h4>Legal Services</h4>
                            <p>We will then provide the legal service which we were requested.</p>
                        </li>
                    </ol>
                </p>
            </div>
              
            </LayoutOne>
        </Fragment>    
    );
}

export default BecomingAClient;