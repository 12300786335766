import React from 'react';   
import SectionTitle from '../SectionTitles/SectionTitle';


const StartHomeContent = () => {

  return ( 
	<section>
        <div className="container"> 
            <div className="row">
                {/* <div>
                    <div>
                        <SectionTitle 
                        title="Muddasir Law"  
                        />  
                     </div>

                    
                </div> */}

                <div>
                    <div>
                        <p> We understand that straightforward and practical solutions are most warranted when someone is seeking legal advice. That is why we take the time to break down a legal solution into plain and simple language for the client. Whether you are an ordinary individual or a large corporate entity, we can help you navigate your issues with efficient, precise and cost-effective legal solutions. Our practise areas include business and corporate law, wills & estates, civil litigation, and real estate. Our mission is to provide excellent client services and ensure that clients are aware of all developments regarding their legal matters with us. </p>
                    </div>
                </div>
                        
                    
            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>
  );
};

export default StartHomeContent;
