import React from 'react'; 
import ContactInfo from './ContactInfo'
import Form from './Form'
import SectionTitle from "../SectionTitles/SectionTitle";
  
const ContactForm = () => {

    return (
        <div className="contact-section" id="contact">
            <div className="container"> 
                {/* Heading */}
                <SectionTitle
                  title="Mississauga" 
                />
                {/* End: Heading */}
                <div className="row inner-contact"> 
                    
                      <div className="col-sm-12 contact_info">
                          <ContactInfo location="Mississauga"/>
                      </div>

                      <div className="row">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.746482151558!2d-79.6389771835082!3d43.590996664466985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x66785773f6baff69%3A0x7f5469ab318a46be!2sMuddasir%20Law!5e0!3m2!1sen!2sca!4v1641692013520!5m2!1sen!2sca" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>        
                      </div>

                      {/* <div className="col-sm-12">
                          <Form />
                      </div> */}
                       
                </div> 
                {/* Heading */}
                <SectionTitle
                  title="Toronto Location" 
                />
                {/* End: Heading */}
                <div className="row inner-contact"> 
                    
                      <div className="col-sm-12 contact_info">
                          <ContactInfo location="Toronto"/>
                      </div>

                      {/* <div className="row">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.746482151558!2d-79.6389771835082!3d43.590996664466985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x66785773f6baff69%3A0x7f5469ab318a46be!2sMuddasir%20Law!5e0!3m2!1sen!2sca!4v1641692013520!5m2!1sen!2sca" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>        
                      </div> */}

                      {/* <div className="col-sm-12">
                          <Form />
                      </div> */}
                       
                </div> 
            </div> 
        </div>

    );
}

export default ContactForm;