import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"; 

const PageHeader = ({ title,bgImg, mblImg, services=true }) => {
    const imageUrl = useWindowWidth() >= 415 ? bgImg : mblImg;


    return ( 

    <header id="page-top" className="blog-banner" style={{backgroundImage: `url(${imageUrl})` }}>
        <div className="container" id="blog">
            <div className="row blog-header text-left">
                <div className="col-sm-12"> 
                { services && (<h4 style={{ color: "#0096FF" }}><Link to={process.env.PUBLIC_URL + "/"} style={{ color: "#0096FF" }}> Services </Link> &gt; {title} </h4>) }
                    <h3 style={{ color: "white" }}>{title}</h3>
                    {/* <div className="header_button">
                        <a target="_blank" href="https://calendly.com/muddasirlaw/consult" style={{ marginLeft:"-70" }}>Book a free consultation with lawyer</a>
                    </div> */}
                </div>
            </div>
        </div>  
    </header> 
    
  );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth ] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    },[]);

    return windowWidth;
};
 
export default PageHeader;

 