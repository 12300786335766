import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";  
import ServiceDetails from "../../templates/ServiceDetails/index";



const Notary = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Notary | Virtual notary | Muddasir Law</title>
		  <meta
			name="description"
			content="As a Toronto notary and lawyer, we pride ourselves on providing online notary services in the the whole of Ontario."
		  />
		</MetaTags> 
		<LayoutOne>

		    <ServiceDetails service="Notary" />

		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default Notary;