import React from "react";  
import ChooseUsList from "./ChooseUsList";  
import WhyChoosData from '../../data/WhyChooseUs/why-choose'  
import SectionTitle from "../SectionTitles/SectionTitle";

/*Baclground Image*/
import videoBg from '../../assets/images/white_wallpaper.jpg' 


const ChooseUs = () => {
  return (
    
	<section className="why_choos_section" id="quote">
	    <div className="container"> 
			<div className="row">  
				<div className="why_chs">
					{/* Heading */}
					<SectionTitle style={{color: 'white'}}
						title="Why Choosing us?" 
					/>
					{/* End: Heading */}

					{/*Start:   About List */} 
					{WhyChoosData.map(choose=>(
						<ChooseUsList 
							key={choose.id} 
							title={choose.title} 
							text={choose.text}  
							icon={choose.icon}  
						/>
					))}
					{/*End:  About List*/}

				</div> 
			</div> 
        </div> 
	</section>  

  );
};

export default ChooseUs;
