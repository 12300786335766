import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";  
import ServiceDetails from "../../templates/ServiceDetails/index";



const RealEstate = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Real Estate Lawyer | Virtual in-house counsel | Muddasir Law</title>
		  <meta
			name="description"
			content="As a Toronto real estate lawyer, we pride ourselves on providing real estate services in the greater Toronto area as well."
		  />
		</MetaTags> 
		<LayoutOne>

		    <ServiceDetails service="Real Estate" />

		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default RealEstate;