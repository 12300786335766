import React , {Fragment, useState, useReducer } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from '../../../layouts/LayoutOne';
import PageHeader from '../../../components/PageHeader';
import PageWrapper from '../../../components/PageWrapper';
import { send } from 'emailjs-com';
import{ init } from '@emailjs/browser';

const formReducer = (state, event) => {
    if(event.reset) {
        return {
          apple: '',
          count: 0,
          first_name: '',
          last_name: '',
          'gift-wrap': false,
        }
    }
    return {
      ...state,
      [event.name]: event.value
    }
}
   
function InitialIntake() {
    const serviceID = 'service_p784p8v';
    const templateID = 'template_93msl29';
    const userID = 'et9sVQughI_l70mrf';
    init('et9sVQughI_l70mrf');

    const [formData, setFormData] = useReducer(formReducer, {
        count: 100,
    });
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);

        send(serviceID, templateID, formData, userID)
        .then((response) => {
            setSubmitting(false);
            setFormData({
                reset: true
            })
            setSuccess(true);
            console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';
        setFormData({
          name: event.target.name,
          value: isCheckbox ? event.target.checked : event.target.value,
        });
    }

  return(
    <Fragment>
        <MetaTags>
            <title> Initial Intake | Business Immigration | Muddasir Law</title>
            <meta
            name="description"
            content="Business Immigration - Initial Intake - Muddasir Law"
            />
        </MetaTags>

        <LayoutOne>
            <PageHeader
            bgImg={require('../../../assets/images/header/bl.jpg').default}
            title="Initial Intake for business immigration" 
            services= {false}
            />

            

            <PageWrapper classes="Form-page">
                
                {success &&
                    <div class="alert alert-success" role="alert">
                        Your form was submitted successfully. Please send us an email at <a href="mailto:info@muddasirlaw.com" class="alert-link">info@muddasirlaw.com</a> after form submission so we can confirm we have received your data.
                    </div>
                }
                
                <div>
                    {submitting && 
                        <div>
                            Submitting ... 
                        </div>
                    }
                    <div className="form">
                        <form onSubmit={handleSubmit}>
                            <fieldset disabled={submitting}>
                                <h4>Personal Information</h4>
                                <div className="input-group"> 
                                    <div class="form-group">
                                        <label>
                                            First Name
                                            <input name="first_name" className="form-control" onChange={handleChange} value={formData.first_name || ''}/>
                                        </label>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>
                                            Middle Name
                                            <input name="middle_name" className="form-control" onChange={handleChange} value={formData.middle_name || ''}/>
                                        </label>
                                    </div>
                                    
                                    <div class="form-group">
                                        <label>
                                            Last Name
                                            <input name="last_name" className="form-control" onChange={handleChange} value={formData.last_name || ''}/>
                                        </label>    
                                    </div>
                                </div>
                                <div className="input-group">
                                    <div class="form-group">
                                        <label>
                                        <p>Date of birth</p>
                                        <input type="date" name="dob" className="form-control" onChange={handleChange} value={formData.dob || ''}/>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="input-group">
                                        <label>
                                        <p>Telephone Number</p>
                                        <input type="tel" name="tel" className="form-control" onChange={handleChange} value={formData.tel || ''}/>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className="input-group ">
                                        <label>
                                        <p>Email</p>
                                        <input type="email" name="email" className="form-control" onChange={handleChange} value={formData.email || ''}/>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div className='input-group'>
                                        <label>
                                            <p>Country of Residence</p>
                                            <input name="residence" className="form-control" onChange={handleChange} value={formData.residence || ''}/>
                                        </label>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <div className='input-group'>
                                        <label>
                                            <p>Passport Number</p>
                                            <input name="passport_number" className="form-control" onChange={handleChange} value={formData.passport_number || ''}/>
                                        </label>
                                    </div>
                                </div>
                                
                                <div className='form-group'>
                                    <div className='input-group'>
                                        <label>
                                            <p>Country of Citizenship</p>
                                            <input name="citizenship" className="form-control" onChange={handleChange} value={formData.citizenship || ''}/>
                                        </label>
                                    </div>
                                </div>
                                
                            </fieldset>
                            <fieldset disabled={submitting}>
                                <h4>Human Capital Factors</h4>
                                
                                <div className='input-group'>
                                    <label><p>Do you have experience owning or managing a business?</p></label>
                                    <select name="experience_type" className="form-control" onChange={handleChange} value={formData.experience_type || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="owning">I have experience owning a business</option>
                                        <option value="managing">I have experience managing a business</option>
                                    </select>
                                </div>

                                <div className='input-group' hidden={formData.experience_type !== 'owning' || !formData.experience_type }>
                                    <label><p>How many months of business ownership experiece do you have?</p></label>
                                    <select name="years_of_experience" className="form-control" onChange={handleChange} value={formData.years_of_experience || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="<24">Less than 24 months</option>
                                        <option value="24-47">24 - 47 months</option>
                                        <option value="48-59">48 - 59 months</option>
                                        <option value="60+">60+ months</option>
                                    </select>
                                </div>

                                <div className='input-group' hidden={formData.experience_type !== 'managing' || !formData.experience_type }>
                                    <label><p>How many months of business management experiece do you have?</p></label>
                                    <select name="years_of_experience_m" className="form-control" onChange={handleChange} value={formData.years_of_experience_m || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="<24">Less than 24 months</option>
                                        <option value="24-47">24 - 47 months</option>
                                        <option value="48-59">48 - 59 months</option>
                                        <option value="60+">60+ months</option>
                                    </select>
                                </div>

                                <div className='input-group'>
                                    <label>
                                    <p>Does your business have a minimum of a 20 per cent increase in revenue in past two consecutive years?</p>
                                    </label>    
                                    
                                    <select name="high_growth" className="form-control" onChange={handleChange} value={formData.high_growth || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    
                                </div>

                                <div className='input-group'>
                                    <label>
                                        Has your business been exporting at least 20 per cent of total goods/services to a foreign market each year within the last two years?
                                    </label>    
                                    
                                    <select name="export" className="form-control" onChange={handleChange} value={formData.export || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    
                                </div>

                                <div className='input-group'>
                                    <label>
                                    Do you have experience of trasferable skills within the last five years?
                                    </label>

                                    <select name="t_skills" className="form-control" onChange={handleChange} value={formData.t_skills || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                                <div className='input-group'>
                                    <label>
                                    What level of education have you completed?
                                    </label>

                                    <select name="education" className="form-control" onChange={handleChange} value={formData.education || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="1">No post-secondary education</option>
                                        <option value="2">Post-secondary degree or diploma - two-year program</option>
                                        <option value="3">Post-secondary degree or diploma - three-year program or longer</option>
                                    </select>
                                    
                                </div>

                                <div className='input-group'>
                                    <label>
                                    Have you given the ielts?
                                    </label>

                                    <select name="ielts" className="form-control" onChange={handleChange} value={formData.ielts || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>

                            </fieldset>
                            <fieldset disabled={submitting}>
                                <h4>Investment Factors</h4>
                                
                                <div className='input-group'>
                                    <label>
                                    Where, in Ontario, will your business be located?
                                    </label>

                                    <select name="location" className="form-control" onChange={handleChange} value={formData.location || ''}>
                                        <option value="">--Please choose an option--</option>
                                        <option value="inside_gta">Inside GTA</option>
                                        <option value="outside_gta">Outside GTA</option>
                                    </select>
                                    
                                </div>

                                <div className='input-group' hidden={ formData.location !== 'inside_gta' || !formData.location }>
                                    <label>
                                    What is your personal net worth?
                                        <select name="net_worth_gta" className="form-control" onChange={handleChange} value={formData.net_worth_gta || ""}>
                                            <option value="">--Please choose an option--</option>
                                            <option value="1">Less than $800,000</option>
                                            <option value="2">$800,000 to $2,499,999</option>
                                            <option value="3">$2,500,000 or more</option>
                                        </select>
                                    </label>
                                </div>

                                <div className='input-group' hidden={formData.location !== 'outside_gta' || !formData.location}>
                                    <label>
                                    What is your personal net worth?
                                        <select name="net_worth" className="form-control" onChange={handleChange} value={formData.net_worth || ''}>
                                            <option value="">--Please choose an option--</option>
                                            <option value="1">Less than $400,000</option>
                                            <option value="2">$400,000 to $999,9999</option>
                                            <option value="3">$1,000,000 or more</option>
                                        </select>
                                    </label>
                                </div>

                                <div className='input-group' hidden={formData.location !== 'inside_gta' || !formData.location }>
                                    <label>
                                    How much will you invest in your proposed business?</label>
                                        <select name="invest_gta" className="form-control" onChange={handleChange} value={formData.invest_gta || ''}>
                                            <option value="">--Please choose an option--</option>
                                            <option value="1">Less than $600,000</option>
                                            <option value="2">$600,000 to $1,499,999</option>
                                            <option value="3">$1,500,000 to $2,999,999	</option>
                                            <option value="4">$3,000,000 or more</option>
                                        </select>
                                    
                                </div>

                                <div className='input-group' hidden={formData.location !== 'outside_gta' || !formData.location}>
                                    <label>
                                    How much will you invest in your proposed business?
                                        <select name="invest" className="form-control" onChange={handleChange} value={formData.invest || ''}>
                                            <option value="">--Please choose an option--</option>
                                            <option value="1">Less than $200,000</option>
                                            <option value="2">$200,000 to $499,999</option>
                                            <option value="3">$500,000 to $799,999</option>
                                            <option value="4">$800,000 or more</option>
                                        </select>
                                    </label>
                                </div>

                                <div className='input-group'>
                                    <label>
                                    Have you visited Ontario(Canada) related to your proposed business?
                                        <select name="visit" className="form-control" onChange={handleChange} value={formData.visit || ''}>
                                            <option value="">--Please choose an option--</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </label>
                                </div>
                            </fieldset>
                            <fieldset disabled={submitting}>
                                <h4>Business Concept</h4>

                                <div className='input-group'>
                                    <label>Provide a description of your proposed business.</label>
                                    <textarea id="business_description" rows="8" name="business_description" className="form-control" onChange={handleChange} value={formData.business_description || ''}></textarea>
                                </div>

                                <div className='input-group'>
                                    <label>Describe any market research you have conducted.</label>
                                    <textarea id="market_research" rows="8" name="market_research" className="form-control" onChange={handleChange} value={formData.market_research || ''}></textarea>
                                </div>

                                <div className='input-group'>
                                    <label>Explain how your business will generate significant economic benefit to Ontario.</label>
                                    <textarea id="benefit" rows="8" name="benefit" className="form-control" onChange={handleChange} value={formData.benefit || ''}></textarea>
                                </div>

                                <div className='input-group'>
                                    <label>Provide a breakdown of how you intend to allocate your capital investment.</label>
                                    <textarea id="investment_breakdown" rows="8" name="investment_breakdown" className="form-control" onChange={handleChange} value={formData.investment_breakdown || ''}></textarea>
                                </div>
                                
                                <div className='input-group'>
                                    <label>How many new jobs will be created for Canadians (from your business)? Please mention the role for each.</label>
                                    <textarea id="jobs_creation" rows="4" name="jobs_creation" className="form-control" onChange={handleChange} value={formData.jobs_creation || ''}></textarea>
                                </div>
                                
                            </fieldset>
                            <button type="submit" id="submit" className="form-control" disabled={submitting} style={{backgroundColor: '#38AEE6' }}>Submit</button>
                        </form>

                        {success &&
                            <div class="alert alert-success" role="alert">
                                Your form was submitted successfully. Please send us an email at <a href="mailto:info@muddasirlaw.com" class="alert-link">info@muddasirlaw.com</a> after form submission so we can confirm we have received your data.
                            </div>
                        }
                    </div>
                </div>
            </PageWrapper>
            
        </LayoutOne>
    </Fragment>
  )
}

export default InitialIntake;