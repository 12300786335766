import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../../layouts/LayoutOne";  
import ServiceDetails from "../../../templates/ServiceDetails/index";



const Incorporation = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Business Lawyer | Business Incorporation Canada (Federal & Ontario) | Virtual in-house counsel</title>
		  <meta
			name="description"
			content="As a Toronto & GTA business lawyer, we pride ourselves on providing business incorporation serivces for Ontario and Federal."
		  />
		</MetaTags> 
		<LayoutOne>

		    <ServiceDetails service="Incorporation" subservice={true} />

		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default Incorporation;