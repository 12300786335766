import React from 'react';
import contact from '../../data/Contact/contact'

 
const COntactInfo = (props) => {

    var index=0;

    if (props.location == "Mississauga")
        index = 0
    else if (props.location == "Toronto")
        index = 1
    else
        index = 0

    return (
            <div className="row">
                <div className="bottom_contact col-sm-4 col-xs-12"><i className="icon_pin_alt"></i>
                    <p>Muddasir Law </p>
                    <h4>{contact[index].Address}</h4>
                </div>
                <div className="bottom_contact col-sm-4 col-xs-12"><i className="icon_phone"></i>
                    <p>Toll Free Number</p>
                    <h4>{contact[index].Phone}</h4>
                </div>
                <div className="bottom_contact col-sm-4 col-xs-12"><i className="icon_clock_alt"></i>
                    <p>Opening Hours</p>
                    <h4>Mon-Fri 9am - 6pm</h4>
                </div>
            </div>                            
    )
}

export default COntactInfo;