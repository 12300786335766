import React, { Fragment } from "react";
import PageHeader from '../../components/PageHeader'
import ServicesData from '../../data/Services/service-one'
import SubServicesData from '../../data/Services/sub-services'
import PageWrapper from "../../components/PageWrapper";
import RelatedServices from "../../components/RelatedServices";
import ServiceContent from "./ServiceContent";
import CallNow from "../../components/CallNow"; 

const ServiceDetails = (props) => {
	var service = false;

	if (props.subservice == true){
		service = SubServicesData.find((serviceItem) => serviceItem.title === props.service);
	}
	else {
		service = ServicesData.find((serviceItem) => serviceItem.title === props.service);
	}	
	

	return (
		<Fragment>
			<PageHeader
				bgImg={require('../../assets/images/'+service.bgImg).default}
				title={service.title}
				mblImg={require('../../assets/images/'+service.mobileImg).default}
			/>

			<CallNow />

			<PageWrapper classes="single_service_section">
				<ServiceContent
					service={service}
					subservice={props.subservice}
				/>
			</PageWrapper>

			<RelatedServices />
		</Fragment>
	);
};

export default ServiceDetails;
