import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import LandingPageOne from "./pages/LandingPageOne";
import LandingPageTwo from "./pages/LandingPageTwo";
import Service from "./pages/Service";
import SingleService from "./pages/SingleService";
import Gallery from "./pages/Gallery";
import SingleGallery from "./pages/SingleGallery";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Privacy from "./pages/PrivacyPage";
import Terms from "./pages/termsPage";
import SingleBlog from "./pages/SingleBlog";
import Blog from "./pages/Blog";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import BecomingAClient from "./pages/BecomingAClient"
import BusinessLaw from "./pages/Services/BusinessLaw";
import TechnologyLaw from "./pages/Services/TechnologyLaw";
import RealEstate from "./pages/Services/RealEstate";
import WillsAndEstate from "./pages/Services/WillsAndEstate";
import CivilLitigation from "./pages/Services/CivilLitigation";
import Notary from "./pages/Services/Notary";
import InitialIntake from "./pages/Forms/BusinessImmigration/InitialIntake";
import IncorporationIntake from "./pages/Forms/incorporation/incorporation-intake";
import Incorporation from "./pages/Services/BusinessLaw/Incorporation";


function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home"}`}
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-two"}`}
            component={HomeTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/home-three"}`}
            component={HomeThree}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/landingpage-one"}`}
            component={LandingPageOne}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/landingpage-two"}`}
            component={LandingPageTwo}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about"}`}
            component={About}
          />
          <Route exact path={`${process.env.PUBLIC_URL + '/service'}`}
            component={Service}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/business-law'}`}
            component={BusinessLaw}
          />
          <Route path={`${process.env.PUBLIC_URL + '/business-law/incorporation-federal-ontario'}`}
            component={Incorporation}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/technology-law'}`}
            component={TechnologyLaw}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/real-estate'}`}
            component={RealEstate}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/wills-and-estate'}`}
            component={WillsAndEstate}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/civil-litigation'}`}
            component={CivilLitigation}
          />
          <Route path={`${process.env.PUBLIC_URL + '/service/notary'}`}
            component={Notary}
          />
          <Route path={`${process.env.PUBLIC_URL + '/gallery/:galleryID'}`}
            component={SingleGallery}
          />
          <Route path={`${process.env.PUBLIC_URL + '/blog/:blogID'}`}
            component={SingleBlog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gallery"}`}
            component={Gallery}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy-policy"}`}
            component={Privacy}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/terms-and-conditions"}`}
            component={Terms}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/team"}`}
            component={Team}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            component={Blog}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/becoming-a-client"}`}
            component={BecomingAClient}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/business-immigration/initial-intake"}`}
            component={InitialIntake}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/incorporation/initial-intake"}`}
            component={IncorporationIntake}
          />
          <Route exact component={NotFound} />

        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;