import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../../layouts/LayoutOne";  
import ServiceDetails from "../../templates/ServiceDetails/index";



const TechnologyLaw = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Technology Lawyer | Virtual in-house counsel | Muddasir Law</title>
		  <meta
			name="description"
			content="As a Toronto business lawyer, we pride ourselves on providing technology law services in the greater Toronto area as well."
		  />
		</MetaTags> 
		<LayoutOne>

		    <ServiceDetails service="Technology Law" />

		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default TechnologyLaw;