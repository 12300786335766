import { Fragment } from "react"




const CallNow = () => {

    return (
        <Fragment>
            <div className="CallNowSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 text-section">
                            <p className="small">
                                Free Consultation
                            </p>
                            <p className="text">Book a free initial consultation with a lawyer</p>
                        </div>
                        <div className="col-md-4 CN-button-mobile">
                        <a target="_blank" href="https://calendly.com/muddasirlaw/consult"><button className="CN-button"><i className="icon_calendar" ></i> Book Now</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default CallNow;