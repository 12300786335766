import React, { Fragment } from "react";  
import { ListGroup, ListGroupItem, Nav, Row, Col, Card } from "react-bootstrap";
import { Image } from "react";
import Tab from 'react-bootstrap/Tab';

const IncorporationServices = () => {
    return (
        <Fragment>
            <h3 className="centerAlign">Business Formation Advice from Trusted Corporate Lawyers</h3>

            <p>As experienced business owners understand one of the most important considerations when starting an enterprise is how the company should be organized. Making decisions at this point can have a significant impact on the company moving forward. Muddasir Law has over a decade of experience to assist our clients with this kind of advice.</p>

            <div className="row">
                <h3 className="centerAlign">Incorporation Packages</h3>
            </div>

            <div>
                <div className="packages-row">
                    
                        <div className="packages package1">
                            <h3>Federal Incorporation</h3>
                            <p className="price">Price: $599
                            <br></br>
                            All-inclusive (Including government fees + Filling Fees + Minute books + other documnets) </p>
                            <p></p>
                                                        
                        </div>
                        
                        <div className="packages package2">
                            <h3>Ontario Incorporation</h3>
                            <p className="price">Price: $699
                            <br></br>
                            All-inclusive (Including government fees + Filling Fees + Minute books + other documnets)</p>
                        </div>

                        {/* <div className="packages package3">
                            <h3>Urgent Incorporation</h3>
                            <b className="exclaim">Incorporate in 24 hours!</b>
                            <p className="price">Price: $999
                            <br></br>
                            All-inclusive (Including government fees)</p>
                        </div> */}
                </div>
            </div>

            <div className="serviceIncludes">
                <p> All packages includes: </p>
                <ul className="service-list">
                    <li>Certificate of Incorporation</li>
                    <li>Articles of Incorporation</li>
                    <li>By-Laws</li>
                    <li>Initial Organizational Resolutions</li>
                    <li>Share Issuance & Electronic Share Certificates</li>
                    <li>Registers & Ledgers</li>
                    <li>Corporate Name Searches (Pre-Nuans & Nuans)</li>
                    <li>Business Number</li>
                    <li>Form 1 or Form 2 Initial Returns</li>
                    <li>Remote Processing through E-Signing</li> 
                    <li>Legal Advice</li>
                    <li>Government Fees</li>
                    <li>3rd Party Fees & Disbursements</li>
                    <li>Electronic Minute Book</li>
                </ul>
            </div>

        </Fragment>
    );
};

export default IncorporationServices;