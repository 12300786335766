import React, { useState, useEffect } from 'react';
import SliderData from '../../data/Slider/home-one'
import {Link} from "react-router-dom"; 

import desktopImage from '../../assets/images/header/Muddasirlaw.jpg';
import mobileImage from '../../assets/images/header/mobile/home.jpg';

const Image = () => {
    const imageUrl = useWindowWidth() >= 415 ? desktopImage : mobileImage;

    return (
        <div className="header-image" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="container">
                        <div className="heading">
                            
                        </div>
                    
                </div>
            </div>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth ] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    },[]);

    return windowWidth;
};

export default Image;