import React from 'react';   
import AboutList from "./AboutList"; 
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";

// About Image
import aboutImg from '../../../assets/images/muddasirzaib2.jpg'

const AboutContent = () => {

  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div>
                    <div className="about_list">
                        {/* Start: Heading */}
                        <SectionTitle 
                        title="Muddasir Law"  
                        />
                     </div>

                    
                </div>

                <div className="col-md-8 col-sm-12 about_bottom_warp" >
                    <div className="about_list">
                    <p>We understand that straightforward and practical solutions are most warranted when someone is seeking legal advice. That is why we take the time to break down a legal solution into plain and simple language for the client. Whether you are an ordinary individual or a large corporate entity, we can help you navigate your issues with efficient, precise and cost-effective legal solutions. Our practise areas include business and corporate law, wills & estates, civil litigation, and real estate. Our mission is to provide excellent client services and ensure that clients are aware of all developments regarding their legal matters with us.</p>
                    
                    <p>Muddasir Law is a solo lawyer law firm delivering legal services to individuals, entrepreneurs, small businesses, freelancers, startups and established companies.</p>
                    
                    <p>Muddasir is a law graduate and has an MBA. Currently, he is an LLM (Business Law) student at York University Toronto. He is licenced to practice law as a barrister & solicitor in Ontario.</p>
                    
                    <p>Muddasir primarily works on corporate & commercial matters from business startups, acquisitions, growth, fundings to reorganizations. Further, he also advises on wills & estates, real estate conveyancing, drafting of agreements, regulatory compliance and business management matters. He has broad working experience with various small and large organizations, which adds a unique diversity to his knowledge.</p>

                    <p>Muddasir has experience in registering and maintaining various forms of businesses, including sole proprietorships, partnerships, incorporation of companies and societies. He has working experience with various multinational corporations and banks in business management areas, where he was in charge of more than fifty employees.</p>
                    </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-4 col-sm-12" style={{textAlign: "center"}}> 

                    { /*  About Right Image  */}
                    <img src={aboutImg} alt="" style={{ alignSelf: 'center' }}/>

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutContent;
