import React, { Fragment } from "react";  
import { ListGroup, ListGroupItem, Nav, Row, Col, Card } from "react-bootstrap";
import { Image } from "react";
import Tab from 'react-bootstrap/Tab';

import startupImg from '../../assets/images/services/startup2.jpg';
import agreementImg from '../../assets/images/services/agreements.jpg';
import maintainingImg from '../../assets/images/services/maintaining.jpg';
import sellingImg from '../../assets/images/services/selling.jpg';

import SectionTitle from "../../components/SectionTitles/SectionTitle";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";

import businessImg from "../../assets/images/services/team.jpg";
import adviceImg from "../../assets/images/services/advice.jpg";

import successionImg from "../../assets/images/hands.jpg";


const TechnologyServices = () => {
    return (
        <Fragment>
            <SectionTitle
                    smallTitle="What We Do"
                    title="Technology Business Services" 
                />

            <div class="row row-cols-1 row-cols-md-3 g-4 gy-4">
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Business Registration / Incorporation</h5>
                            <p class="card-text">Choosing the right form of a business is one of the most important decisions before initiating a business venture. We provide a free initial consultation and consider various factors to advise a specific form of business.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Privacy Law</h5>
                            <p class="card-text">We advise on the development of privacy policy, cookie policy and terms and conditions.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Software Development Contract Management</h5>
                            <p class="card-text">Contract drafting for SAAS-based companies, software developers and software clients.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Employment Matters</h5>
                            <p class="card-text">If you are looking to hire an employee you must take care of the applicable employment standard to your business structure. A properly drafted employment contract can help avoid employment-related claims.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Intellectual Property Rights</h5>
                            <p class="card-text">We help in contract drafting and registration of issues related to intellectual property rights.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card h-100">
                        <div class="card-body">
                            <h5 class="card-title">
                                <span className="serv_icon_list"><i className="icon_documents_alt"></i></span>
                                Business Legal Risk Assessment & Advice</h5>
                            <p class="card-text">If you are starting up a business venture, there can several legal ways through which you can initiate and grow. We provide a free initial meeting with the lawyer who will analyze several factors before suggesting you a specific business structure.</p>
                        </div>
                    </div>
                </div>
            </div>

            <p className="mt-5">If you are starting up a business venture, there can several legal ways through which you can initiate and grow. We provide a free initial meeting with the lawyer who will analyze several factors before suggesting you a specific business structure.</p>
        </Fragment>
    );
};

export default TechnologyServices;