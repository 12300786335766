import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";  
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";

import contact from '../../data/Contact/contact'


const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);

    const phoneStr = "tel:" + contact[0].Phone.replace(/ /g, "")
    const phone2Str = "tel:" + contact[0].Phone2.replace(/ /g, "")
    const faxStr = "fax:" + contact[0].Fax.replace(/ /g, "")
    const emailStr = "mailto:" + contact[0].Email

    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
                {/*  Start:About  */}
                <div className="col-lg-3 col-sm-12">
					<Widget title="Business Lawyer">
                        <p>Muddasir Law is a corporate and real estate law firm delivering legal services to entrepreneurs, small businesses, freelancers, startups and established companies across Canada. In many cases, we act as your virtual general counsel.</p>
                    </Widget>
                </div> 
                {/*  End:About  */}

                 {/* Start:Quick Link  */} 
                {/* <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Helpful Links">
                        <List classes="recent-post helpful_post">
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/service"}>Our services</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/becoming-a-client"}>Becoming a client</Link>
                            </LI>
                            <LI>
                                <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                            </LI> 
                        </List>
                    </Widget> 
                </div>  */}
                {/*  End:Quick Link  */}

                {/*  Start:Services  */} 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Our Services">
                        <List classes="recent-post helpful_post">
                            <li><a href="/service/business-law"> Business Law </a></li>
                            <li><a href="/service/wills-and-estate"> Wills and Estate </a></li>
                            <li><a href="/service/civil-litigation"> Civil Litigation </a></li>
                            <li><a href="/service/real-estate"> Real Estate </a></li>
                            <li><a href="/service/notary"> Notary </a></li>
                        </List>
                    </Widget> 
                </div> 
                {/*  End:Services  */}

                {/*  Start:Location  */} 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Mississauga Location">
                        <List classes="recent-post helpful_post">
                            <li><a href={phoneStr}> <b>Call (Toll Free):</b> {contact[0].Phone} </a></li>
                            <li><a href={phone2Str}> <b>Call:</b> {contact[0].Phone2} </a></li>
                            <li><a href={faxStr}> <b>Fax:</b> {contact[0].Fax} </a></li>
                            <li><a href={emailStr}> <b>Email:</b> {contact[0].Email} </a></li>
                            <li><a > <b>Address:</b> {contact[0].Address} </a></li> 
                        </List>
                    </Widget> 
                </div> 
                {/*  End:Location  */}

                {/*  Start:Location  */} 
                <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Toronto Location">
                        <List classes="recent-post helpful_post">
                            <li><a href={phoneStr}> <b>Call (Toll Free):</b> {contact[1].Phone} </a></li>
                            <li><a href={phone2Str}> <b>Call:</b> {contact[1].Phone2} </a></li>
                            <li><a href={faxStr}> <b>Fax:</b> {contact[1].Fax} </a></li>
                            <li><a href={emailStr}> <b>Email:</b> {contact[1].Email} </a></li>
                            <li><a > <b>Address:</b> {contact[1].Address} </a></li> 
                        </List>
                    </Widget> 
                </div> 
                {/*  End:Location  */}

                
 
                {/*  Start:Latest post   */}
                {/* <div className="col-lg-3 col-sm-12"> 
                    <Widget title="Latest Articles">
                        <List classes="recent-post">
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/blog"}>Loren ipsum dolor consectetur  </Link>
                            </LI>
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/blog"}>Loren ipsum dolor consectetur  </Link>
                            </LI>
                            <LI>  
                                <Link to={process.env.PUBLIC_URL + "/blog"}>Loren ipsum dolor consectetur  </Link>
                            </LI>
                        </List>
                    </Widget> 
                </div> */} 
                {/*  End:Latest post  */}


                {/*  Start:Newsletter  */} 
                {/* <div className="col-lg-4 col-sm-12"> 
                    <Widget title="Subcribe now"> */}
                 
                        {/*  Start:Subscribe  */}

                        {/* <div className="news_letter_wrp">
                            <p>Subscribe our newsletter to get more update on Muddasir Law </p>
                            <form className="footer_subs">
                            <input className="form-input" placeholder="Enter Your Email Address" type="text" />
                            <button type="submit" className="form-button"></button>
                            </form> */}
                        
                            {/*  END MAILCHIMP FORM  */}                         
                        {/* </div> 
                    </Widget> 
                </div>  */}
                {/*  End:Start Subscribe  */} 
 

                {/*  Start:Subfooter  */}
                <div className="subfooter"> 
                    <div className="copyright_text"> Muddasir Law | 
                        <Link to={process.env.PUBLIC_URL + "/privacy-policy"}> Privacy Policy</Link> |
                        <Link to={process.env.PUBLIC_URL + "/terms-and-conditions"}> Terms and Conditions</Link>
                    </div> 

                    {/* SOCIAL ICON */} 
                    <div className="footer_social"> 
                        <ul className="footer_social_icons">
                        <li> <a href={contact[0].Facebook}><i className="fa fa-facebook"></i></a> </li>
                        <li> <a href={contact[0].Instagram}><i className="fa fa-instagram"></i></a> </li>
                        <li> <a href={contact[0].Youtube}><i className="fa fa-youtube"></i></a> </li>
                        <li> <a href={contact[0].Linkedin}><i className="fa fa-linkedin"></i></a> </li>
                        </ul>
                    </div>

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="arrow_triangle-up"></span>
                    </button> 
                </div> 
                {/* End:Subfooter  */}

            </div>
        </div> 

    </footer> 

        )
 
}

 
export default Footer;