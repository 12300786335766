import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader';

const PrivacyPage = () => {
    
    return(
        <Fragment>
            <MetaTags>
              <title> Privacy Policy | Muddasir Law</title>
              <meta
                name="description"
                content="Privacy Policy - Muddasir Law"
              />
            </MetaTags>

            <LayoutOne>
              <PageHeader
                bgImg={require('../assets/images/page_bg.jpg').default}
                title="Privacy Policy" 
                services = {false}
              />
              
              <div className='container'>
                <p>This privacy policy will explain where, how and why we collect and use your information. This information is used to provide services to you. Muddasir Law is committed to protecting our clients' privacy and security. We will comply with all Canadian data privacy laws including the Personal Information Protection and Electronic Documents Act (S.C. 2000, c. 5). The privacy principles below govern how we collect, use and store personal information obtained while providing legal services. </p>

                <p>The Data Protection Officer is responsible for ensuring compliance with data privacy laws. However, other individuals at Muddasir law may have the responsibility for the daily collection and processing of personal information. They may also be delegated the responsibility of the privacy officer.</p>

                <p>We collect two types of information through our Website: “Personal Information” and “non-personal information”.</p>

                <p>This policy defines personal information as any information that identifies a person. It includes employee records and customer and supplier records. However, it does not include the name of an employee or title. Non-personal information is information that is not personally identifiable. The nature of your interactions with us will determine the types of information that we collect.</p>

                <p>You agree to our privacy policies by using the muddasirlaw.com site (the "website") and submitting the information through the website forms.</p>

                <h4>Disclaimer</h4>
                <p>The information provided on this website should not be considered legal advice. The contents of this website may not include current legal developments, and you must contact your lawyer or us to apply the law to your situation specifically. Please do not provide us with your confidential information unless we establish a formal solicitor-client relationship with you by a legal service agreement. </p>


                <h4>What information do we collect:</h4>

                <p>Information you provide: We may collect Personal Information about you, such as your name, home address, billing information, account information, and information related to your legal matters. This information is also used to provide legal advice and services. We may also collect Personal Information about clients or their officers, beneficiaries, witnesses, family members, the adverse parties or parties in interest, and information about potential buyers, shareholders, security-holders and investors, targets, competitors, customers, or clients.</p>

                <p>Device information: We collect information about the device from which you accessed the website. We collect information like your operating system, IP address and location-based information (geolocation).</p>

                <p>Cookies: We also collect information from web browsers to learn more about how you use the website and make improvements as needed. Your activity and information will be automatically recorded in our systems. These may include your Internet Protocol ("IP") address, Media Access Control ("MAC") address, country, location, browser type, web page visited before visiting the website, information that you search for on this website, local preferences, identification numbers associated your devices, mobile carrier, date stamps, system configuration information and metadata about your files and other interactions with it.</p>

                <p>Geolocation Information: You may allow us access to your location. This can be done by either granting the Site access when asked or via your device's settings for location services. These settings can be changed on your device but may affect your access to and use of the Services.</p>

                <p>Google Analytics: We use Google Analytics to help keep our website relevant and understand how users use it. The third-party cookies of google analytics gather information about the use of our website. </p>

                <p>This is the link to the privacy policy for google: https://policies.google.com/privacy?hl=en-US</p>

                <p>Cloudflare: cookies are used by our website caching service namely Cloudflare. They store information such as location, device and IP to be able to serve a highly performant website and make your user experience good.</p>

                <p>Here is the link to the privacy policy for Cloudflare: https://www.cloudflare.com/en-gb/privacypolicy/</p>

                <p>Calendly: Calendly is used to arrange all our meetings. It takes meeting information such as time of meeting and meeting type and your name and purpose of the meeting. Here is their privacy policy of calendly: https://calendly.com/privacy</p>

                <h4>Payment Processing</h4>

                <p>We may collect your credit card information and payment information via a third-party payment processor. The payment processor, Lawpay, is used as of the date this Privacy Policy was last updated:</p>

                <p>LawPay, with their privacy policy available at https://www.lawpay.com/terms/privacy/</p>

                <p>We may embed their forms in our legal service agreement or host them on our website, but when you submit your payment details, you are giving your details to the payment processor. Third-party payment processors might have their own agreements that may apply to you. Although we won't have your whole credit card number, you will be able to bill your credit card. We may also have access to the certain card and payment information such as your name, billing address, and expiration date. Please contact us if you have any questions about our payment processor.</p>


                <h4>Use of personal information</h4>

                <p>All private information obtained from the user of this website will be used to for the following things:</p>

                <ul>
                  <li>
                    <h4>Provide Services</h4>
                    <p>
                    We will use the information initially to determine if we may act as your legal counsel. If we do act as your legal counsel, then that information will be used for the purpose of which it was given to us. This includes using this information for billing purposes and for communicated with the client/user as well. 
                    </p>
                  </li>

                  <li>
                    <h4>Website performance</h4>
                    <p>
                    We wish to provide you with the best service possible. Some of the tracking information (as explained above) will be used to improve the website performance.
                    </p>
                  </li>
                  <li>
                    <h4>Fulfill all legal requirements</h4>
                    <p>Personal information may be used to fulfill all legal requirements including those of the Law Society of Ontario and To enforce compliance with our Terms and applicable laws, rules and regulations.</p>
                  </li>
                  <li>
                    <h4>We do not sell any information to third parties.</h4>
                  </li>
                </ul>

                <h4>Sharing Personal Information and Content if Required by Law</h4>
                  
                <p>We may disclose personal information and any information that we collect, when required by the law, for example, pursuant to subpoena or court order or any other legal process within any jurisdiction. If we are legally required to share your personal data or information, we or may not be able to provide you a written notice of the disclosure.</p>

                <h4>Your Personal Information May Not Be Stored in Canada</h4>
                <p>Since we use servers of third-party providers that may store and backup your personal data in locations inside and outside Canada.</p>


                <h4>Consent</h4>
                <p>Personal information is only obtained, used, or shared with the user's permission, except where required by the law. How we obtain consent can differ based on the nature of the information requested. We will seek consent in all instances when the information sought is sensitive, such as health or income information.</p>

                <p>Typically, we seek consent for disclosure or use of personal information prior to the time of the collection. However, if the information is required for the new purpose, we may seek additional consent. In certain situations, obtaining consent could be ineffective. Federal laws such as the Personal Information Protection and Electronic Documents Act and provincial privacy laws allow for certain exceptions when it is difficult or not feasible to get consent. We will comply with lawful requirements in all instances.</p>


                <h4>RETENTION</h4>
                <p>We retain all personal information collected for as long as necessary to achieve the purpose of collecting it and ensure compliance with the applicable laws. Your consent to the purpose(s) remains in force when you end our relationship with you. The collected information can be stored and processed anywhere in the world through us, our affiliates, or unaffiliated service providers per the requirements outlined in this privacy statement. Additionally, according to applicable laws in these different jurisdictions, foreign police, courts, or regulatory authorities may be able to access your personal information under certain conditions.</p>

                <h4>REQUESTS FOR ACCESS TO AND CORRECTION OF PERSONAL INFORMATION</h4>
                <p>Privacy laws that are in force allow any person to inspect and request correction of any errors or omissions in their personal information in our possession or control. We will respond within a reasonable amount of time and will do our best to reply as precisely and as complete as is possible. All organizations with which the personal information is shared will be promptly informed (if) any changes are made to it.
                Individual clients can look over any personal information regarding themselves that we hold within our premises by contacting their lawyer. People who aren't clients can request access to the personal information we have about them by writing addressed to the Data Protection Office.</p>
                <p>
                <b>Data Protection Officer - Muddasir Law </b><br />
                90 Burnhamthorpe Rd W 14th Floor, Suite 1400 <br />
                Mississauga, ON L5B 3C3<br />
                </p>
                <p>
                data_protection@muddasirlaw.com <br />
                +1 888 417 7097
                </p>

                <p>We will allow access to this Personal Information, subject to the exceptions outlined or mandated by applicable privacy laws. Examples of these exceptions include information covered by the privilege of lawyer-client or information gathered in the process of the formal procedure for settling disputes, information regarding an individual whose disclosure could expose confidential information about a business or other information that is disclosed to the police or other authorities when we are required by law to keep information from being disclosed. Suppose you have concerns about the information we've given you or would like to report any inaccuracies regarding the information we have provided. In that case, you can reach the Data Protection Officer or, for clients, their attorney.</p>

              </div>
              
            </LayoutOne>
        </Fragment>    
    );
}

export default PrivacyPage;