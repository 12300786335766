import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import PageHeader from '../components/PageHeader';

const TermsPage = () => {
    
    return(
        <Fragment>
            <MetaTags>
              <title> Terms and Conditions | Muddasir Law</title>
              <meta
                name="description"
                content="Terms and Conditions - Muddasir Law"
              />
            </MetaTags>

            <LayoutOne>
              <PageHeader
                bgImg={require('../assets/images/page_bg.jpg').default}
                title="Terms and Conditions" 
                services= {false}
              />
              
            <div className='container'>
              <p>These Terms of Usage are crucial and define our relationship with users on the website, www.muddasirlaw.com (the “Website”). These terms and conditions are binding on every website user to the maximum extent provided by the law. By visiting the website, you agree to these terms and conditions. We can modify these terms or cease to operate the services or the website at any time at our sole discretion.</p>

              <h4>NO LEGAL ADVICE</h4>

              <p>The website's content is general and is only intended to be used for informational purposes, and can be changed without notice and should not be relied upon by users for professional or legal advice regarding any specific issue. </p>


              <h4>NO SOLICITOR-CLIENT RELATIONSHIP</h4>

              <p>The use of the website does not establish solicitor-client relationship between the user and the lawyer. The website could allow you to access or communicate with the lawyer through the use of email or another forum. The receipt and response of such messages do not establish an attorney-client relationship unless mentioned by us explicitly and formerly.</p>
 
              <h4>LIABILITY</h4>

              <p>To the extent that is permitted by law, in no circumstance shall we be held responsible or obligated for any damage or loss whatsoever (including without the limitation of the direct or indirect, specific consequential, incidental damages, punitive, exemplary, or other damages). including, but not limited to, any losses of profits, data or programs, goodwill or other intangible or tangible property, or other damages) arising from the use of the website, whether or not we knew, were advised or should have known the possibility of such damages.</p>               

              <h4>OWNERSHIP OF INTELLECTUAL PROPERTY</h4>

              <p>We are the sole owners of the intellectual property rights of whatever kind, that is related to the Website. Other trademarks and service marks, as well as graphics and logos that are used to describe the Website, could be trademarks of third-party companies. This Agreement will not confer on you our intellectual property. All rights, titles, and interests in or relating to this property will remain exclusively in the hands of us or such a third party, as the case may be.</p>

              <h4>LINKS TO OTHER SITES</h4>
              <p>The Website may include links to other Internet websites and resources. We do not take any responsibility and do and shall not be held liable for the content of these sites and resources. The links to other sites are provided for your convenience only. Links on our website are not an  endorsement, and we don't guarantee or represent the accuracy or authenticity of the information on these websites. The links you access and the websites that are corresponding to them are entirely at your risk.</p>


              <h4>REPRESENTATIONS & WARRANTIES</h4>

              <p>We do not make any warranties or representations with regard to any Content of the website. User acknowledge and agree that the user is responsible to evaluate the authenticity and relibility of any Content of the website, accessed by using the website. The user is always under the obligation of reviewing any Content to confirm its accuracy and completeness.</p>

            </div>
              
            </LayoutOne>
        </Fragment>    
    );
}

export default TermsPage;