import React, { Fragment } from "react";
import { Link } from "react-router-dom"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import PageHeader from '../components/PageHeader'
import About from '../components/About/home-one'
import Skill from '../components/Skill'; 
import Team from '../components/Team/home-one'; 
import BusinessServices from "../templates/ServiceDetails/BusinessServices";


const Aboutpage = () => {
 
    return(
        <Fragment>
            <MetaTags>
              <title>About us | Muddasir Law</title>
              <meta
                name="title"
                content="About us | Muddasir Zaib | Business Lawyer | Technology Lawyer."
              />
              <meta 
                name="description"
                content="Muddasir Zaib - An internationally trained bussiness Lawyer providing services in Mississauga, Toronto and surrounding areas."
              />
            </MetaTags>

            <LayoutOne>
            
                <PageHeader
                    bgImg={require('../assets/images/page_bg.jpg').default}
                    title="About us" 
                />

                <About />

                <div style={{background: "rgb(16, 37, 46)", color: "white", padding: "3em"}}> 
                    Click here for more <Link to={process.env.PUBLIC_URL + "/service/business-and-corporate-law?id=1"} style={{ color: "#0096FF" }}> business services. </Link>.
                </div>

        
            </LayoutOne>
        </Fragment>
            
    ); 
};

export default Aboutpage;   