import React , {Fragment, useState, useReducer } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from '../../../layouts/LayoutOne';
import PageHeader from '../../../components/PageHeader';
import PageWrapper from '../../../components/PageWrapper';
import { send } from 'emailjs-com';
import{ init } from '@emailjs/browser';

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const formReducer = (state, event) => {
    if(event.reset) {
        return {
                      
        }
    }
    return {
      ...state,
      [event.name]: event.value
    }
}
   
function IncorporationIntake() {
    const serviceID = 'service_p784p8v';
    const templateID = 'template_93msl29';
    const userID = 'et9sVQughI_l70mrf';
    init('et9sVQughI_l70mrf');

    const [formData, setFormData] = useReducer(formReducer, {
        count: 100,
        incorp_country: 'Canada',
        directors: [{
            first_name: '',
            middle_name: '',
            last_name: ''
        }]
    });
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);

        send(serviceID, templateID, formData, userID)
        .then((response) => {
            setSubmitting(false);
            setFormData({
                reset: true
            })
            setSuccess(true);
            console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
            console.log('FAILED...', err);
        });
    }

    const setValFromComp = (val, comp) => {
        console.log(val, comp);
        setFormData({
            name: comp,
            value: val,
        });
    }

    const setValFromCompArray = (val, comp, index, arrayName) => {
        let formValues = ''
        if(arrayName == 'directors' ){
            formValues=formData.directors;
            formValues[index][comp] = val;
            setFormData({
                name: 'directors',
                value: formValues
            })
        }
    }

    const setAddress = (prefix) => {
        console.log(prefix);
        setFormData({name: prefix+'_address_line1', value: formData.reg_office_address_line1});
        setFormData({name: prefix+'_address_line2', value: formData.reg_office_address_line2});
        setFormData({name: prefix+'_city', value: formData.reg_office_city});
        setFormData({name: prefix+'_country', value: formData.reg_office_country});
        setFormData({name: prefix+'_state', value: formData.reg_office_state});
        setFormData({name: prefix+'_postal_code', value: formData.reg_office_postal_code});
    }

    const addFormFields = (arrayName) => {
        console.log(formData.arrayName, arrayName)
        if(arrayName == 'directors' ){
            setFormData({
                name: arrayName,
                value: [...formData.directors, {
                    first_name:'', 
                    middle_name:'', 
                    last_name:'', 
                    address_line1:'', 
                    address_line2:'', 
                    city:'', 
                    country:'', 
                    state:'', 
                    postal_code:''
                }]
            })    
        };
        
        // if(arrayName == 'officers'){
        //     setFormData({
        //         name: arrayName,
        //         value: [...formData.officers, {
                    
        //         }]
        //     })
        // }
        
    }

    const removeFormFields = (i, arrayName) => {
        let formValues = ''
        
        if(arrayName == 'directors' ){
            formValues = formData.directors;
            formValues.splice(i, 1);
            setFormData({
                name:'directors',
                value: formValues
            });
        }
    }

    const handleFormArraysChange = (i,e,arrayName) => {
        let formValues = ''
        if(arrayName == 'directors' ){
            formValues=formData.directors;
            formValues[i][e.target.name] = e.target.value;
            setFormData({
                name: 'directors',
                value: formValues
            })
        }
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setFormData({
          name: event.target.name,
          value: isCheckbox ? event.target.checked : event.target.value,
        });
    }

    const directors = formData.directors.map((element, index) => (
        <div key={index} className="directors-list">
            <h5>Director {index+1} details</h5>
            <p><b>Director's Name</b></p>
            <div className="row form-row"> 
                <div class="form-group col-md-4">
                    <label>First Name</label>
                    <input name="first_name" className="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} value={element.first_name || ''}/>
                </div>
                
                <div class="form-group col-md-4">
                    <label>Middle Name</label>
                    <input name="middle_name" className="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} value={element.middle_name || ''}/>
                </div>
                
                <div class="form-group col-md-4">
                    <label>Last Name</label>
                    <input name="last_name" className="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} value={element.last_name || ''}/>
                </div>
            </div>
            <div className='row'>   
                <b className='label-address'>Director's Address</b>
                <button type="button" className="btn btn-primary btn-sm btn-address">Same as company's registered office</button>
            </div>
            <div class="form-group form-row">
                <label for="address_line1">Address</label>
                <input type="text" name="address_line1" class="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} id="address_line1" placeholder="1234 Main St" value={element.address_line1 || ''} />
            </div>
            
            <div className="row form-row">
                <div class="form-group col-md-8">
                    <label for="address_line2">Address 2</label>
                    <input type="text" name="address_line2" class="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} id="address_line2" placeholder="Apartment, studio, or floor" value={element.address_line2 || ''} />
                </div>
                <div class="form-group col-md-4">
                    <label for="city">City</label>
                    <input type="text" name="city" class="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} id="city" value={element.city || ''} />
                </div>
            </div>
            
            <div class="form-row input-group">
                <div class="form-group col-md-4">
                    <label>Country</label>
                    <CountryDropdown value={element.country || ''} onChange={(val) => setValFromCompArray(val, 'country', index, 'directors')} classes="form-control"/>
                </div>
                <div class="form-group col-md-4">
                    <label>State/Province</label>
                    <RegionDropdown
                        disableWhenEmpty={true}
                        country={element.country}
                        value={element.state || ''}
                        onChange={(val) => setValFromCompArray(val, 'state', index, 'directors')}
                        classes="form-control" />   
                </div>
                <div class="form-group col-md-4">
                    <label for="postal_code">Postal Code</label>
                    <input type="text" name='postal_code' class="form-control" onChange={e => handleFormArraysChange(index, e, 'directors')} id="postal_code" value={element.postal_code || ''}/>
                </div>
            </div>
            {
                index ? 
                  <button type="button"  className="btn btn-danger btn-remove btn-sm" onClick={() => removeFormFields(index, 'directors')}>Remove Director</button> 
                : null
              }
        </div>
    ));

  return(
    <Fragment>
        <MetaTags>
            <title> Initial Intake | Business Immigration | Muddasir Law</title>
            <meta
            name="description"
            content="Business Incorporation - Initial Intake - Muddasir Law"
            />
        </MetaTags>

        <LayoutOne>
            <PageHeader
            bgImg={require('../../../assets/images/header/bl.jpg').default}
            title="Initial Intake for business incorporation" 
            services= {false}
            />

            <PageWrapper classes="Form-page">
                
                {success &&
                    <div class="alert alert-success" role="alert">
                        Your form was submitted successfully. Please send us an email at <a href="mailto:info@muddasirlaw.com" class="alert-link">info@muddasirlaw.com</a> after form submission so we can confirm we have received your data.
                    </div>
                }
                
                <div>
                    {submitting && 
                        <div>
                            Submitting ... 
                        </div>
                    }
                    <div className="form">
                        <form onSubmit={handleSubmit}>
                            <fieldset disabled={submitting}>
                                <h4>Incorporator's Information</h4>

                                <p><b>Incorporator's Name</b></p>
                                <div className="row form-row"> 
                                    <div class="form-group col-md-4">
                                        <label>First Name</label>
                                        <input name="first_name" className="form-control" onChange={handleChange} value={formData.first_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Middle Name</label>
                                        <input name="middle_name" className="form-control" onChange={handleChange} value={formData.middle_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Last Name</label>
                                        <input name="last_name" className="form-control" onChange={handleChange} value={formData.last_name || ''}/>
                                    </div>
                                </div>

                                <p><b>Incorporator's Address</b></p>
                                <div class="form-group form-row">
                                    <label for="incorp_address_line1">Address</label>
                                    <input type="text" name="incorp_address_line1" class="form-control" onChange={handleChange} id="incorp_address_line1" placeholder="1234 Main St" value={formData.incorp_address_line1 || ''} />
                                </div>
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="incorp_address_line2">Address 2</label>
                                        <input type="text" name="incorp_address_line2" class="form-control" onChange={handleChange} id="inputAddress2" placeholder="Apartment, studio, or floor" value={formData.incorp_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="incorp_city">City</label>
                                        <input type="text" name="incorp_city" class="form-control" id="incorp_city" value={formData.incorp_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.incorp_country || ''} onChange={(val) => setValFromComp(val, 'incorp_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.incorp_country}
                                            value={formData.incorp_state || ''}
                                            onChange={(val) => setValFromComp(val, 'incorp_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="incorp_postal_code">Postal Code</label>
                                        <input type="text" name='incorp_postal_code' class="form-control" onChange={handleChange} id="incorp_postal_code" value={formData.incorp_postal_code || ''}/>
                                    </div>                       
                                </div>
                            </fieldset>

                            <fieldset disabled={submitting}>
                                <h4>Company Information</h4>
                        
                                <div class="form-group">
                                    <label for="jurisdiction">What is your jurisdiction of incorporation?</label>    
                                    <select name="jurisdiction" className="form-control" onChange={handleChange} value={formData.jurisdiction || ''}>
                                        <option value="ontario">Ontario</option>
                                        <option value="canada">Canada</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Provide a list of your proposed names of your corporation. (Please suggest 4 names in order of preference)</label>
                                    <div className="col-md-8">
                                        <input name="incorp_name1" className="form-control" placeholder="Name 1" onChange={handleChange} value={formData.incorp_name1 || ''} style={{margin: '5px'}}/>
                                        <input name="incorp_name2" className="form-control" placeholder="Name 2" onChange={handleChange} value={formData.incorp_name2 || ''} style={{margin: '5px'}}/>
                                        <input name="incorp_name3" className="form-control" placeholder="Name 3" onChange={handleChange} value={formData.incorp_name3 || ''} style={{margin: '5px'}}/>
                                        <input name="incorp_name4" className="form-control" placeholder="Name 4" onChange={handleChange} value={formData.incorp_name4 || ''} style={{margin: '5px'}}/>
                                    </div>
                                </div> 

                                <p><b>Company's Registered Office</b></p>
                                
                                <div class="form-group form-row">
                                    <label for="reg_office_address_line?gatewayAdapt=glo2ara1">Address</label>
                                    <input type="text" name="reg_office_address_line1" class="form-control" onChange={handleChange} id="reg_office_address_line1" placeholder="1234 Main St" value={formData.reg_office_address_line1 || ''} />
                                </div>
                                
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="reg_office_address_line2">Address 2</label>
                                        <input type="text" name="reg_office_address_line2" class="form-control" onChange={handleChange} id="reg_office_address_line2" placeholder="Apartment, studio, or floor" value={formData.reg_office_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="reg_office_city">City</label>
                                        <input type="text" name="reg_office_city" class="form-control" id="reg_office_city" onChange={handleChange} value={formData.reg_office_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.reg_office_country || ''} onChange={(val) => setValFromComp(val, 'reg_office_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.reg_office_country}
                                            value={formData.reg_office_state || ''}
                                            onChange={(val) => setValFromComp(val, 'reg_office_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="reg_office_postal_code">Postal Code</label>
                                        <input type="text" name='reg_office_postal_code' class="form-control" onChange={handleChange} id="reg_office_postal_code" value={formData.reg_office_postal_code || ''}/>
                                    </div>                       
                                </div>

                                <div className='row'>   
                                    <b className='label-address'>Company's Mailing Address</b>
                                    <button type="button" onClick={() => setAddress('comp_mailing')} className="btn btn-primary btn-sm btn-address">Same as company's registered office</button>
                                </div>
                                <div class="form-group form-row">
                                    <label for="comp_mailing_address_line1">Address</label>
                                    <input type="text" name="comp_mailing_address_line1" class="form-control" onChange={handleChange} id="comp_mailing_address_line1" placeholder="1234 Main St" value={formData.comp_mailing_address_line1 || ''} />
                                </div>
                                
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="comp_mailing_address_line2">Address 2</label>
                                        <input type="text" name="comp_mailing_address_line2" class="form-control" onChange={handleChange} id="comp_mailing_address_line2" placeholder="Apartment, studio, or floor" value={formData.comp_mailing_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="comp_mailing_city">City</label>
                                        <input type="text" name="comp_mailing_city" class="form-control" onChange={handleChange} id="comp_mailing_city" value={formData.comp_mailing_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.comp_mailing_country || ''} onChange={(val) => setValFromComp(val, 'comp_mailing_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.comp_mailing_country}
                                            value={formData.comp_mailing_state || ''}
                                            onChange={(val) => setValFromComp(val, 'comp_mailing_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="comp_mailing_postal_code">Postal Code</label>
                                        <input type="text" name='comp_mailing_postal_code' class="form-control" onChange={handleChange} id="comp_mailing_postal_code" value={formData.comp_mailing_postal_code || ''}/>
                                    </div>
                                </div>  

                                <div className='row'>   
                                    <b className='label-address'>Company's Principal Place of Business</b>
                                    <button type="button" onClick={() => setAddress('place_of_bus')} className="btn btn-primary btn-sm btn-address">Same as company's registered office</button>
                                </div>

                                <div class="form-group form-row">
                                    <label for="place_of_bus_address_line1">Address</label>
                                    <input type="text" name="place_of_bus_address_line1" class="form-control" onChange={handleChange} id="place_of_bus_address_line1" placeholder="1234 Main St" value={formData.place_of_bus_address_line1 || ''} />
                                </div>
                                
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="place_of_bus_address_line2">Address 2</label>
                                        <input type="text" name="place_of_bus_address_line2" class="form-control" onChange={handleChange} id="place_of_bus_address_line2" placeholder="Apartment, studio, or floor" value={formData.place_of_bus_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="place_of_bus_city">City</label>
                                        <input type="text" name="place_of_bus_city" class="form-control" onChange={handleChange} id="place_of_bus_city" value={formData.place_of_bus_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.place_of_bus_country || ''} onChange={(val) => setValFromComp(val, 'place_of_bus_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.place_of_bus_country}
                                            value={formData.place_of_bus_state || ''}
                                            onChange={(val) => setValFromComp(val, 'place_of_bus_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="place_of_bus_postal_code">Postal Code</label>
                                        <input type="text" name='place_of_bus_postal_code' class="form-control" onChange={handleChange} id="place_of_bus_postal_code" value={formData.place_of_bus_postal_code || ''}/>
                                    </div>
                                </div>  

                                <p><b>Company Contact Information</b></p>
                                <div className='form-group'>
                                    <label>What is the corporation's official email address?</label>
                                    <input name="comp_email" className="form-control" onChange={handleChange} value={formData.comp_email || ''}/>
                                </div>                               

                            </fieldset>

                            <fieldset disabled={submitting}>
                                <h4>Company Directors</h4>
                                {directors}
                                <button className="btn btn-primary btn-add" type="button" onClick={() => addFormFields('directors')}>Add Director</button>
                            </fieldset>

                            <fieldset disabled={submitting}>
                                <h4>Company President</h4>
                                <p><b>President's Name</b></p>
                                <div className="row form-row"> 
                                    <div class="form-group col-md-4">
                                        <label>First Name</label>
                                        <input name="pres_first_name" className="form-control" onChange={handleChange} value={formData.pres_first_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Middle Name</label>
                                        <input name="pres_middle_name" className="form-control" onChange={handleChange} value={formData.pres_middle_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Last Name</label>
                                        <input name="pres_last_name" className="form-control" onChange={handleChange} value={formData.pres_last_name || ''}/>
                                    </div>
                                </div>
                                <div className='row'>   
                                    <b className='label-address'>President's Address</b>
                                    <button type="button" onClick={() => setAddress('pres')} className="btn btn-primary btn-sm btn-address">Same as company's registered office</button>
                                </div>
                                
                                <div class="form-group form-row">
                                    <label for="pres_address_line1">Address</label>
                                    <input type="text" name="pres_address_line1" class="form-control" onChange={handleChange} id="pres_address_line1" placeholder="1234 Main St" value={formData.pres_address_line1 || ''} />
                                </div>
                                
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="pres_address_line2">Address 2</label>
                                        <input type="text" name="pres_address_line2" class="form-control" onChange={handleChange} id="pres_address_line2" placeholder="Apartment, studio, or floor" value={formData.pres_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="pres_city">City</label>
                                        <input type="text" name="pres_city" class="form-control" onChange={handleChange} id="pres_city" value={formData.pres_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.pres_country || ''} onChange={(val) => setValFromComp(val, 'pres_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.pres_country}
                                            value={formData.pres_state || ''}
                                            onChange={(val) => setValFromComp(val, 'pres_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="pres_postal_code">Postal Code</label>
                                        <input type="text" name='pres_postal_code' class="form-control" onChange={handleChange} id="pres_postal_code" value={formData.pres_postal_code || ''}/>
                                    </div>
                                </div> 
                            </fieldset>

                            <fieldset disabled={submitting}>
                                <h4>Company Secretary</h4>
                                <p><b>Secretary's Name</b></p>
                                <div className="row form-row"> 
                                    <div class="form-group col-md-4">
                                        <label>First Name</label>
                                        <input name="secr_first_name" className="form-control" onChange={handleChange} value={formData.secr_first_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Middle Name</label>
                                        <input name="secr_middle_name" className="form-control" onChange={handleChange} value={formData.secr_middle_name || ''}/>
                                    </div>
                                    
                                    <div class="form-group col-md-4">
                                        <label>Last Name</label>
                                        <input name="secr_last_name" className="form-control" onChange={handleChange} value={formData.secr_last_name || ''}/>
                                    </div>
                                </div>
                                
                                <div className='row'>   
                                    <b className='label-address'>Secretary's Address</b>
                                    <button type="button" onClick={() => setAddress('secr')} className="btn btn-primary btn-sm btn-address">Same as company's registered office</button>
                                </div>

                                <div class="form-group form-row">
                                    <label for="secr_address_line1">Address</label>
                                    <input type="text" name="secr_address_line1" class="form-control" onChange={handleChange} id="secr_address_line1" placeholder="1234 Main St" value={formData.secr_address_line1 || ''} />
                                </div>
                                
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="secr_address_line2">Address 2</label>
                                        <input type="text" name="secr_address_line2" class="form-control" onChange={handleChange} id="secr_address_line2" placeholder="Apartment, studio, or floor" value={formData.secr_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="secr_city">City</label>
                                        <input type="text" name="secr_city" class="form-control" onChange={handleChange} id="secr_city" value={formData.secr_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.secr_country || ''} onChange={(val) => setValFromComp(val, 'secr_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.secr_country}
                                            value={formData.secr_state || ''}
                                            onChange={(val) => setValFromComp(val, 'secr_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="secr_postal_code">Postal Code</label>
                                        <input type="text" name='secr_postal_code' class="form-control" onChange={handleChange} id="secr_postal_code" value={formData.secr_postal_code || ''}/>
                                    </div>
                                </div> 
                            </fieldset>

                            <fieldset disabled={submitting}>
                                <h4>Company Details</h4>
                                <p>Will there be any restriction on the business that the corporation may carry on?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="restriction" id="restriction_yes" onChange={handleChange} value="yes" />
                                    <label class="form-check-label" for="restriction_yes">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="restriction" id="restriction_no" onChange={handleChange} value="no" />
                                    <label class="form-check-label" for="restriction_no">
                                        No
                                    </label>
                                </div>

                                <div className='form-group'>
                                    <label>If you select yes, please explain.</label>
                                    <input name="restriction_explanation" className="form-control" onChange={handleChange} value={formData.restriction_explanation || ''}/>
                                </div>

                                <p>What is the maximum limit of authorize shares?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="max_shares" id="max_shares_unlimited" onChange={handleChange} value="Unlimited" />
                                    <label class="form-check-label" for="max_shares_unlimited">
                                        Unlimited
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="max_shares" id="max_shares_discuss" onChange={handleChange} value="Will discuss with lawyer" />
                                    <label class="form-check-label" for="max_shares_discuss">
                                        Will discuss with lawyer
                                    </label>
                                </div>

                                <p>What would be the corporation's issued share capital?</p>
                                
                                <div className='form-group'>
                                    <label>What is the minimum and maximum number of directors. (Min 1 & max 11)</label>
                                    Min: <input name="min_directors" className="form-control" onChange={handleChange} value={formData.min_directors || ''}/>
                                    Max: <input name="max_directors" className="form-control" onChange={handleChange} value={formData.max_directors || ''}/>
                                </div>

                                <div className='form-group'>
                                    <label>Who are the authorized signing officer(s) for contracts?</label>
                                    <input name="officer_name" className="form-control" onChange={handleChange} value={formData.officer_name || ''}/>
                                </div>

                                
                                <p>How many classes of shares will the corporation have?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="classes_of_shares" id="classes_of_shares_1" onChange={handleChange} value="Common voting shares" />
                                    <label class="form-check-label" for="classes_of_shares_1">
                                        Common voting shares
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="classes_of_shares" id="classes_of_shares_2" onChange={handleChange} value="Common voting plus non voting shares" />
                                    <label class="form-check-label" for="classes_of_shares_2">
                                        Common voting plus non voting shares
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="classes_of_shares" id="classes_of_shares_3" onChange={handleChange} value="Do not understand, will discuss with the lawyer" />
                                    <label class="form-check-label" for="classes_of_shares_3">
                                        Do not understand, will discuss with the lawyer
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="classes_of_shares" id="classes_of_shares_4" onChange={handleChange} value="other" />
                                    <label class="form-check-label" for="classes_of_shares_4">
                                        Other
                                    </label>
                                </div>

                                <div className='form-group'>
                                    <label>If other, please state.</label>
                                    <input name="classes_of_shares_other" className="form-control" onChange={handleChange} value={formData.classes_of_shares_other || ''}/>
                                </div>

                                <p>Any rights, privileges, restrictions or conditions attaching to each class of shares?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="attaching" id="attaching_1" onChange={handleChange} value="Yes, I will explain it to the lawyer" />
                                    <label class="form-check-label" for="attaching_1">
                                        Yes, I will explain it to the lawyer
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="attaching" id="attaching_2" onChange={handleChange} value="No" />
                                    <label class="form-check-label" for="attaching_2">
                                        No
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="attaching" id="attaching_3" onChange={handleChange} value="Do not understand, will discuss with the lawyer." />
                                    <label class="form-check-label" for="attaching_3">
                                        Do not understand, will discuss with the lawyer.
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="attaching" id="attaching_4" onChange={handleChange} value="other" />
                                    <label class="form-check-label" for="attaching_4">
                                        Other
                                    </label>
                                </div>

                                <div className='form-group'>
                                    <label>If other, please state.</label>
                                    <input name="other_attaching" className="form-control" onChange={handleChange} value={formData.other_attaching || ''}/>
                                </div>

                                <p>Would the articles contain any restriction on the issue, transfer or ownership of the shares?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="articles" id="articles1" onChange={handleChange} value="Yes" />
                                    <label class="form-check-label" for="articles1">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="articles" id="articles2" onChange={handleChange} value="No" />
                                    <label class="form-check-label" for="articles2">
                                        No
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="articles" id="articles3" onChange={handleChange} value="Don't know" />
                                    <label class="form-check-label" for="articles3">
                                        Don't know
                                    </label>
                                </div>

                                <p>The quorum for a shareholders' meeting is?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_shareholder" id="quorum_shareholder1" onChange={handleChange} value="One or more shareholders representing 25 per cent of shares entitled to vote" />
                                    <label class="form-check-label" for="quorum_shareholder1">
                                        One or more shareholders representing 25 per cent of shares entitled to vote
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_shareholder" id="quorum_shareholder2" onChange={handleChange} value="Don't know" />
                                    <label class="form-check-label" for="quorum_shareholder2">
                                        Don't know - will discuss with the lawyer
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_shareholder" id="quorum_shareholder1" onChange={handleChange} value="Other" />
                                    <label class="form-check-label" for="quorum_shareholder1">
                                        Other
                                    </label>
                                </div>
                                <div className='form-group'>
                                    <label>If other, please state.</label>
                                    <input name="quorum_shareholder_other" className="form-control" onChange={handleChange} value={formData.quorum_shareholder_other || ''}/>
                                </div>

                                <p>What would be the quorum at directors' meetings?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_director" id="quorum_director1" onChange={handleChange} value="Majority%" />
                                    <label class="form-check-label" for="quorum_director1">
                                        Majority
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_director" id="quorum_director3" onChange={handleChange} value="25%" />
                                    <label class="form-check-label" for="quorum_director3">
                                        25%
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="quorum_director" id="quorum_director4" onChange={handleChange} value="Don't know" />
                                    <label class="form-check-label" for="quorum_director4">
                                        Don't know - will discuss with lawyer
                                    </label>
                                </div>
                                <div className='form-group'>
                                    <label>State minimum number of directors to fulfill quorum.</label>
                                    <input name="quorum_director_min_num" className="form-control" onChange={handleChange} value={formData.quorum_director_min_num || ''}/>
                                </div>


                                <p>Would there be a shareholder's agreement?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement" id="agreement_yes" onChange={handleChange} value="Yes" />
                                    <label class="form-check-label" for="agreement_yes">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement" id="agreement_no" onChange={handleChange} value="No" />
                                    <label class="form-check-label" for="agreement_no">
                                        No
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement" id="agreement_dk" onChange={handleChange} value="Don't Know" />
                                    <label class="form-check-label" for="agreement_dk">
                                        Don't know - will discuss with the lawyer
                                    </label>
                                </div>

                                <p>Would the shareholder's agreement restrict any powers of the directors to manage the corporation in whole or in part?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement_restrict" id="agreement_restrict_yes" onChange={handleChange} value="yes" />
                                    <label class="form-check-label" for="agreement_restrict_yes">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement_restrict" id="agreement_restrict_no" onChange={handleChange} value="no" />
                                    <label class="form-check-label" for="agreement_restrict_no">
                                        No
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="agreement_restrict" id="agreement_restrict_dk" onChange={handleChange} value="Dont know" />
                                    <label class="form-check-label" for="agreement_restrict_dk">
                                        Don't know - will discuss with lawyer
                                    </label>
                                </div>

                                <p>Any Restriction on sale of shares? </p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_pr" onChange={handleChange} value="Pre-emptive rights" />
                                    <label class="form-check-label" for="share_restrict_pr">
                                        Pre-emptive rights
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_pb" value="Piggy-backs" />
                                    <label class="form-check-label" for="share_restrict_pb">
                                        Piggy-backs
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_s" value="Shotgun" />
                                    <label class="form-check-label" for="share_restrict_s">
                                        Shotgun
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_da" value="Drag-along" />
                                    <label class="form-check-label" for="share_restrict_da">
                                        Drag-along
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_rr" value="Right of first Refusal " />
                                    <label class="form-check-label" for="share_restrict_rr">
                                        Right of first Refusal    
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="share_restrict" id="share_restrict_dk" value="Dont Know" />
                                    <label class="form-check-label" for="share_restrict_dk">
                                        Do not understand - Will consult the lawyer
                                    </label>
                                </div>

                                <p>Have you considered applying for the below requirements yet?</p>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_f" onChange={handleChange} value="Federal Income tax account" />
                                    <label class="form-check-label" for="exampleRadios1">
                                        Federal Income tax account
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_g" onChange={handleChange} value="GST/HST account" />
                                    <label class="form-check-label" for="requirements_g">
                                        GST/HST account
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_p" onChange={handleChange} value="Payroll source deduction account" />
                                    <label class="form-check-label" for="requirements_p">
                                        Payroll source deduction account
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_o" onChange={handleChange} value="Ontario employer health tax registration" />
                                    <label class="form-check-label" for="requirements_p">
                                        Ontario employer health tax registration
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_w" onChange={handleChange} value="Ontario workplace safety and insurance board registration" />
                                    <label class="form-check-label" for="requirements_p">
                                        Ontario workplace safety and insurance board registration
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="requirements" id="requirements_n" onChange={handleChange} value="None of the above" />
                                    <label class="form-check-label" for="requirements_p">
                                        None of the above
                                    </label>
                                </div>

                                <div className='form-group'>
                                    <label>What would be your corporation's fiscal year end?</label>
                                    <select id="fiscal_year_end" name="fiscal_year_end" class="form-control" onChange={handleChange} value={formData.fiscal_year_end || ''}>
                                        <option value="January">January</option>
                                        <option value="February">February</option>
                                        <option value="March">March</option>
                                        <option value="April">April</option>
                                        <option value="May">May</option>
                                        <option value="June">June</option>
                                        <option value="July">July</option>
                                        <option value="August">August</option>
                                        <option value="September">September</option>
                                        <option value="October">October</option>
                                        <option value="November">November</option>
                                        <option value="December">December</option>
                                    </select>
                                </div>

                                <p><b>Corporation's Bank Information</b></p>
                                <div className='form-group'>
                                    <label>What is your coporation's bank's name?</label>
                                    <input name="incorp_bank_name" className="form-control" onChange={handleChange} value={formData.incorp_bank_name || ''}/>
                                </div>
                                <label>What is your coporation's bank's address?</label>
                                <div class="form-group form-row">
                                    <label for="bank_address_line1">Address</label>
                                    <input type="text" name="bank_address_line1" class="form-control" onChange={handleChange} id="bank_address_line1" placeholder="1234 Main St" value={formData.bank_address_line1 || ''} />
                                </div>
                                <div className="row form-row">
                                    <div class="form-group col-md-8">
                                        <label for="bank_address_line2">Address 2</label>
                                        <input type="text" name="bank_address_line2" class="form-control" onChange={handleChange} id="inputAddress2" placeholder="Apartment, studio, or floor" value={formData.bank_address_line2 || ''} />
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="bank_city">City</label>
                                        <input type="text" name="bank_city" class="form-control" onChange={handleChange} id="bank_city" value={formData.bank_city || ''} />
                                    </div>
                                </div>
                                
                                <div class="form-row input-group">
                                    <div class="form-group col-md-4">
                                        <label>Country</label>
                                        <CountryDropdown value={formData.bank_country || ''} onChange={(val) => setValFromComp(val, 'bank_country')} classes="form-control"/>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label>State/Province</label>
                                        <RegionDropdown
                                            disableWhenEmpty={true}
                                            country={formData.bank_country}
                                            value={formData.bank_state || ''}
                                            onChange={(val) => setValFromComp(val, 'bank_state')}
                                            classes="form-control" />   
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="bank_postal_code">Postal Code</label>
                                        <input type="text" name='bank_postal_code' class="form-control" onChange={handleChange} id="bank_postal_code" value={formData.bank_postal_code || ''}/>
                                    </div>                       
                                </div>
                            </fieldset>
                          
                            <button type="submit" id="submit" className="form-control" disabled={submitting} style={{backgroundColor: '#38AEE6' }}>Submit</button>
                        </form>

                        {success &&
                            <div class="alert alert-success" role="alert">
                                Your form was submitted successfully. Please send us an email at <a href="mailto:info@muddasirlaw.com" class="alert-link">info@muddasirlaw.com</a> after form submission so we can confirm we have received your data.
                            </div>
                        }
                    </div>
                </div>
            </PageWrapper>
            
        </LayoutOne>
    </Fragment>
  )
}

export default IncorporationIntake;