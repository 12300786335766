import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slider from '../components/Slider/SliderOne'
import About from '../components/About/home-one'
import StartHomeContent from '../components/Home'
import Services from '../components/Services/home-two'
import WhyChooseUs from '../components/WhyChooseUs'
import Video from '../components/Video'
import Funfact from '../components/FunFact/home-one'
import Gallery from '../components/Gallery/Gallery';
import Team from '../components/Team/home-one';
import PricingTable from '../components/PricingTable/home-one';
import Blog from '../components/Blog/home-one';
import Testimonial from '../components/Testimonials/home-one';
import LogoContainer from '../components/BrandLogo';
import ContactForm from '../components/ContactForm';
import Image from '../components/HeaderImage/Image';
import CallNow from "../components/CallNow";

const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Playfair+Display&display=swap" rel="stylesheet" />
        <title>Muddasir Law - Barrister & Solicitor | Home</title>
        <meta
          name="Muddasir Law"
          content="Business Lawyer"
        />
        <meta
          name="description"
          content="Muddasir Law can help you navigate your issues with efficient, precise and cost-effective legal solutions. Our practise areas include business and corporate law, wills & estates, civil litigation, and real estate."
        />
      </MetaTags>
      <LayoutOne>
        <Image />
        <CallNow />
        <StartHomeContent />
        <Services />
        <About />
        <WhyChooseUs />
        <ContactForm />
        <LogoContainer /> 
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
